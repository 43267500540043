<template>
    <div>
        <page-title :heading="$t('settings.lang_user_create') " show-previous-button :subheading="$t('settings.lang_user_create')" :icon=icon></page-title>
        <div class="app-main__inner">
            <create-user-component />
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../../Layout/Components/PageTitle.vue";
    import CreateUserComponent from "../../../../components/settings/basic/users/CreateUserComponent";
    export default {
        components: {
            CreateUserComponent,
            PageTitle,
        },
        data: () => ({
            icon: 'pe-7s-add-user icon-gradient bg-tempting-azure',
        })
    }
</script>