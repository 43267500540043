<template>
  <v-container fluid>
    <v-card elevation="0">
      <v-card-title class="ma-0 pa-0">
        {{ this.$t('erp.lang_baseData') }}
      </v-card-title>

      <v-divider/>

      <v-card-text class="ma-0 pa-0">
        <v-form lazy-validation ref="form">
          <v-row>
            <v-col cols="12" md="4" sm="6">
              <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :label="this.$t('customers.lang_cust_prename')" :rules="[ v => !!v]"
                            @focus="showTouchKeyboard" dense outlined
                            v-model="form.createUserPreName"/>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :label="this.$t('customers.lang_cust_name')" :rules="[ v => !!v]"
                            @focus="showTouchKeyboard" dense outlined
                            v-model="form.createUserLastName"/>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-select :data-layout="KEYBOARD.KEYSETS.NORMAL"
                        :items="this.salutations" :label="this.$t('customers.lang_cust_anrede')"
                        :rules="[ v => !!v]"
                        @focus="showTouchKeyboard"
                        dense item-text="name" item-value="id" outlined
                        v-model="form.createUserSalutation"/>
            </v-col>
            <v-col cols="12" md="4" sm="6">
              <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :label="this.$t('settings.lang_personalNo')" @focus="showTouchKeyboard"
                            dense outlined v-model="form.personalNumber"/>
            </v-col>

            <v-col cols="12" md="4" sm="6" v-if="this.$store.getters['permissions/checkModule'](26)">
              <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            @focus="showTouchKeyboard" dense @blur="checkTokenDup" :loading="loadingToken" :disabled="loadingToken"
                            :label="$t('settings.lang_userToken')" outlined v-model="form.createUserUserToken" :rules="[tokenRules]" />
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-select :data-layout="KEYBOARD.KEYSETS.NORMAL" :items="this.PermGroup"
                        :label="this.$t('settings.lang_userRights')"
                        :rules="[ v => !!v]"
                        @focus="showTouchKeyboard" dense item-text="name"
                        item-value="id" outlined
                        v-model="form.createUserUserPermGroup"/>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :label="$t('settings.lang_userIdOnDispensingSystem')" @focus="showTouchKeyboard"
                            dense outlined v-model="form.dispensingKey"/>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-select :label="$t('generic.lang_overrideCashierId')" clearable
                        :items="filteredCashierIDs" item-value="value" item-text="name"
                        dense outlined v-model="form.overrideCashierId"
              />
            </v-col>

            <v-col cols="12">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field :append-icon="this.visible ? 'lnr-eye' : 'lnr-eye'"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="this.$t('generic.lang_neues_passwort')"
                                :rules="[this.passRules.required , this.passRules.min]"
                                :type="this.visible ? 'text' : 'password'"
                                @click:append="visible = !visible"
                                @focus="showTouchKeyboard"
                                class="input-group--focused pb-0"
                                dense
                                :hint="$t('generic.lang_atLeast5Characters')"
                                outlined v-model="form.createUserPassword" value=""/>
                  <password :strength-meter-only="true" class="ma-0" v-model="form.createUserPassword"/>

                </v-col>


                <v-col cols="12" sm="6">
                  <v-text-field :append-icon="this.visible ? 'lnr-eye' : 'lnr-eye'"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="this.$t('generic.lang_neues_passwort_rep')"
                                :rules="[this.passRules.required , this.passRules.passwordMatch]"
                                :type="this.visible ? 'text' : 'password'"
                                @click:append="visible = !visible"
                                @focus="showTouchKeyboard"
                                class="input-group--focused"
                                dense
                                :hint="$t('generic.lang_atLeast5Characters')"
                                outlined v-model="passwordRepeat" value=""/>
                </v-col>
              </v-row>
            </v-col>


            <v-col class="pt-0 mt-0" cols="12" md="4" sm="6">
              <strong>{{ this.$t("settings.lang_userTimeRecordingActive") }}</strong>
              <v-row>
                <v-col>
                  <v-switch :value="1" :label="$t('generic.lang_enabled')" v-model="form.isEmployee"/>
                </v-col>
              </v-row>
            </v-col>

            <v-col class="pt-0 mt-0" cols="12" md="4" sm="6">
              <strong>{{$t('generic.lang_isServiceProvider')}}</strong>
              <v-row>
                <v-col>
                  <v-switch :value="1" :label="$t('generic.lang_enabled')" v-model="form.isServiceProvider"/>
                </v-col>
              </v-row>
            </v-col>

            <v-col class="pt-0 mt-0" cols="12" md="4" sm="6">
              <strong>{{$t('generic.lang_isDeliveryProvider')}}</strong>
              <v-row>
                <v-col>
                  <v-switch :value="1" :label="$t('generic.lang_enabled')" v-model="form.isDeliveryProvider"/>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12">
              <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
                <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                                    :defaultKeySet="touchKeyboard.keySet"
                                    :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                                    :options="touchKeyboard.options" class="internalWidth"
                                    id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
              </div>
            </v-col>

            <v-col class="mt-0 pt-0 text-right" cols="12">
              <v-spacer/>
              <v-btn :disabled="this.loading" :loading="this.loading" @click="create(0)" color="primary">
                {{ this.$t('generic.lang_create') }}
              </v-btn>

              <v-btn :disabled="this.loading" :loading="this.loading" @click="create(1)" color="success">
                {{ this.$t('generic.lang_next') }}
                <v-icon small>arrow_forward_ios</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import mixin from "../../../../../mixins/KeyboardMixIns";
import Password from 'vue-password-strength-meter'
import {ENDPOINTS} from "@/config";
import {mapGetters} from "vuex";

export default {
  name: "PersonalInfoComponent",
  components: {
    Password
  },
  mixins: [mixin],
  props: {
    form: Object,
    PermGroup: Array,
    loading: Boolean
  },
  data: () => {
    return {
      visible: false,
      passwordRepeat: "",
      awaitingSearch:null,
      loadingToken:false,
      dupToken:true,
    }
  },
  watch:{
    'form.createUserUserToken':function(val){
      if(val){
        clearTimeout(this.awaitingSearch);

        this.awaitingSearch = setTimeout(() => {
          if (val && val.toString().length>0) {
            this.checkTokenDup();
          }
        }, 2000);
      }else{
        this.dupToken = true;
      }
    },
  },
  computed: {
    ...mapGetters({
      cashierIDs:"cashierIDs/cashiers",
    }),
    filteredCashierIDs(){
      return this.cashierIDs.map(item => ({
        name:this.$t('generic.lang_kasse') +' '+ item.id + (item.aliasName ? ` (${item.aliasName}) ` : ''),
        value:item.id
      }))
    },
    salutations: function () {
      return [
        {name: this.$t("generic.lang_gender_m"), id: "m"},
        {name: this.$t("generic.lang_gender_w"), id: "w"},
      ];
    },
    passRules: function () {
      return {
        required: value => !!value || this.$t('generic.lang_requiredField'),
        min: v => v && v.length >= 5 || this.$t('generic.lang_atLeast5Characters'),
        passwordMatch: () => (this.form.createUserPassword === this.passwordRepeat) || (this.$t('generic.lang_passwordYouEnteredDontMatch')),
      }
    },
    tokenRules() {
      return this.dupToken || this.$t('generic.lang_tokenAlreadyTaken')
    },
  },
  mounted() {
  },
  methods: {
    create(tab) {
      this.$emit('create', tab);
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    checkTokenDup(){
      this.dupToken=true;
      if(!(this.form.createUserUserToken && this.form.createUserUserToken.length>0))
        return

      this.loadingToken=true;
      this.axios.post(ENDPOINTS.SETTINGS.USER.CHECKDUPTOKEN,{
        usertoken:this.form.createUserUserToken,
      }).then((res) => {
        this.dupToken=res.data.success;
      }).catch(err=>{

      }).finally(()=>{
        this.loadingToken=false;
      })
    }
  }
}
</script>

<style scoped>

</style>
