<template>
    <v-container class="ma-0 pa-0" fluid>
        <v-card class="ma-0" elevation="6" outlined>
            <v-card-text class="pa-0 ma-0">
                <personal-info-component :perm-group="this.PermGroups" @create="create"
                                         ref="personal" v-bind:form="this.form" v-bind:loading="this.loading"/>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    import PersonalInfoComponent from "./createUser/PersonalInfoComponent";
    import {ENDPOINTS} from "../../../../config";
    import {Events} from "../../../../plugins/events";

    export default {
        name: "CreateUserComponent",
        components: {PersonalInfoComponent},
        data: () => {
            return {
                ENDPOINTS,
                form: {
                    createUserArchiveEnabled: false,
                    isEmployee: 1,
                    serviceProvider: 0,
                    isDeliveryProvider :0
                },
                PermGroups: [],
                loading: false,
            }
        },
      mounted() {
            this.getData();
        },
        methods: {
            create(tab) {
                if (!this.$refs.personal.validate()) {
                    return;
                }

                this.loading = true;

                this.axios.post(ENDPOINTS.SETTINGS.USER.CREATE, this.form).then((res) => {
                    if (res.data.status === 'SUCCESS') {
                        Events.$emit("showSnackbar", {
                            message: this.$t('settings.lang_userCreated') + ' ' + this.$t('settings.lang_userid') + ':' + res.data.userID,
                            color: "success"
                        });
                        if (tab == 0)
                            this.$router.push("/settings/basicSettings/displayUsers");
                        else {
                            localStorage.setItem('complete', true);
                            this.$router.push("/settings/basicSettings/editUsers/" + res.data.userID);
                        }
                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                }).catch((err) => {
                    Events.$emit("showSnackbar", {
                        message: err.message,
                        color: "error"
                    });
                }).finally(() => {
                    this.loading = false;
                })
            },
            getData() {

                let id = parseInt(this.$parent.id);
                this.axios.post(ENDPOINTS.SETTINGS.USER.GETCREATE, {
                    userID: id
                }).then((res) => {
                    if (res.status === 200) {

                        res.data.permGrp.forEach((row) => {
                            this.PermGroups.push({name: row[1], id: parseInt(row[0])});
                        });


                    }
                }).catch(() => {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                });
            }
        }
    }
</script>

<style scoped>

</style>
